
import {
  Component,
  Vue
} from 'vue-property-decorator'
import CostService from './services/cost-service'
import CostModal from './components/CostModal.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'Cost',
    components: {
      CostModal
    }
  })
export default class Cost extends Vue {
    protected isTableBusy = false
    protected isBusy = false
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected searchTable = null
    protected currentPage = 1
    protected lines: any = []

    protected fields = [{
      key: 'description',
      label: 'Description',
      sortable: true,
      thStyle: {
        width: '12%'
      }
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'supplier_name',
      label: 'Supplier',
      sortable: true,
      thStyle: {
        width: '14%'
      }
    },
    {
      key: 'currency',
      label: 'Currency',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'unit_of_measurement',
      label: 'UoM',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'quantity',
      label: 'Qty',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'rate',
      label: 'Rate',
      thStyle: {
        width: '8%'
      },
      sortable: true
    },
    {
      key: 'created_user',
      label: 'Created User',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '8%'
      },
      class: 'text-center'
    }
    ]

    get rows () {
      return this.lines.length
    }

    created () {
      this.boot()
    }

    private boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateAllCosts()
    }

    public async populateAllCosts () {
      this.isBusy = true
      const response = await CostService.getAllCosts()
      this.lines = response.data
      this.isBusy = false
    }

    public openModal () {
      (this.$refs.CostModal as any).show()
    }

    protected edit (id: any) {
      (this.$refs.CostModal as any).show(id)
    }

    public deleteCost (cost: any) {
      const msg = `Do you want to delete ${cost.description} ?`
      if (confirm(msg) === true) {
        CostService.delete(cost.id).then((response) => {
          ToastModule.message(response.data.message)
          this.lines = []
          this.populateAllCosts()
        }).catch(error => {
          console.log(error)
        })
      }
    }
}

