
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import Currencies from '@/data/currency.json'
import CostService from '../services/cost-service'
  @Component({
    name: 'CostModal'
  })
export default class CostModal extends Vue {
    protected uoms = ['job', 'person', 'bowser', 'quantity']
    protected types = ['basic', 'specific']
    protected currencies = Currencies
    protected suppliers: any = []
    protected supplier: any = {
      id: null,
      name: null
    }

    protected categories: any = []
    protected category: any = {
      id: null,
      name: null
    }

    protected cost: any = {
      id: null,
      description: null,
      category_id: null,
      type: null,
      supplier_id: null,
      quantity: null,
      unit_of_measurement: null,
      rate: null,
      currency: null
    }

    public show (id = 0) {
      this.populateSupplierNames()
      if (id !== 0) {
        CostService.getCostDetails(id).then((response) => {
          this.cost = response.data
          this.supplier = {
            id: response.data.supplier_id,
            name: response.data.supplier_name
          }
        })
      }
      (this.$refs.CostModal as any).show()
    }

    public hide () {
      this.close();
      (this.$refs.CostModal as any).hide()
    }

    protected populateSupplierNames () {
      CostService.getSupplierNames().then((response) => {
        response.data.forEach((element) => {
          this.suppliers.push({
            value: {
              id: element.id,
              name: element.name
            },
            text: element.name
          })
        })
      })
    }

    protected save () {
      this.cost.category_id = this.category.id
      this.cost.supplier_id = this.supplier.id
      this.cost.created_user = AuthModule.user.fullname
      this.cost.modified_user = AuthModule.user.fullname
      delete this.cost.supplier_name
      CostService.save(this.cost).then((response) => {
        ToastModule.message(response.data.message)
        this.hide();
        (this.$parent as any).populateAllCosts()
      }).catch(error => {
        console.log(error)
      })
    }

    protected close () {
      this.reset(this.cost)
      this.reset(this.category)
      this.reset(this.supplier)
      this.categories = []
      this.suppliers = []
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

