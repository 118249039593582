import APIService from '@/services/api-service'
const resource = '/cost'

class CostService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getAllCosts () {
    return this.connector.get(`${resource}/all`)
  }

  public delete (id: any) {
    return this.connector.delete(`${resource}/${id}`)
  }

  public getCategories () {
    return this.connector.get(`${resource}/categories`)
  }

  public getSupplierNames () {
    return this.connector.get(`${resource}/supplier-names`)
  }

  public getCostDetails (id: any) {
    return this.connector.get(`${resource}/${id}`)
  }

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }
}

export default new CostService()
